:root {
	--btn-hover: #69a9f2;
}

.ql-container {
	min-height: 5rem;
	border-radius: 0 0 5px 5px;
}

.ql-container.ql-snow {
	border: 1px solid var(--COLOR-PRIMARY-THEME);
}

.ql-toolbar {
	background: var(--faint-gray);
	border-radius: 5px 5px 0 0;
}

.quill {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.ql-toolbar.ql-snow {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	padding: 0;
	border: none;
}

.ql-toolbar.ql-snow .ql-formats {
	margin-right: 0;
	display: flex;
	gap: 0.25rem;
}

.ql-editor {
	font-size: 1.8rem;
}

.ql-snow.ql-toolbar button {
	border-radius: 4px;
}

.ql-snow.ql-toolbar button:hover {
	background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active {
	background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button:hover .ql-stroke {
	background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
	background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button:hover .ql-fill {
	background-color: var(--btn-hover);
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
	background-color: var(--btn-hover);
}

.ql-snow .ql-stroke {
	background-color: var(--btn-hover);
}

.ql-snow .ql-fill {
	background-color: var(--btn-hover);
}

.ql-editor.ql-blank::before {
	color: var(--COLOR-PRIMARY-DARK-THEME);
}

.ql-toolbar .ql-stroke {
	fill: none;
	stroke: var(--COLOR-PRIMARY-DARK-THEME);
}

.ql-toolbar .ql-fill {
	fill: var(--COLOR-PRIMARY-DARK-THEME);
	stroke: none;
}

.ql-toolbar .ql-picker {
	color: var(--COLOR-PRIMARY-DARK-THEME);
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
	fill: var(--COLOR-PRIMARY-DARK-THEME);
}
