.filter_panel_mobile {
	border: 2px solid var(--COLOR-PRIMARY-THEME);
	display: flex;
	flex-flow: column wrap;
	gap: 1rem;
	width: fit-content;
	padding: 1.5rem 3rem;
	border-radius: 8px;
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	position: fixed;
	left: 1rem;
	top: 20rem;
	background-color: var(--COLOR-BG);
}

.filter_panel_mobile .filter_panel__wrapper:hover {
	box-shadow: 0px 5px 40px 0px var(--COLOR-CARD-BOX-SHADOW1);
}

.filter_panel_mobile .filter_panel {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;
	gap: 1.2rem;
	color: var(--COLOR-TXT);
}

.filter_panel_mobile .filter__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter_panel_mobile .fitler_type__wrapper {
	display: flex;
	flex-flow: column nowrap;
	gap: 5px;
}

.filter_panel_mobile .select {
	cursor: pointer;
	min-width: 16rem;
	border-radius: 4px;
	padding: 2px 5px;
	border-color: var(--COLOR-PRIMARY-THEME);
}

.filter_panel_mobile .select:hover {
	background-color: var(--COLOR-TERTIARY);
	color: var(--COLOR-BLACK);
}

.filter_panel_mobile .select:focus {
	border-color: var(--COLOR-PRIMARY-THEME);
}

.filter_panel_mobile .input_box {
	font-size: 1.6rem;
	width: 100%;
	border-radius: 4px;
	padding: 5px 1rem;
	border: 1px solid var(--COLOR-INPUT-BORDER);
	background-color: var(--COLOR-BG);
}

.filter__head {
	display: flex;
	width: 100%;
	gap: 1rem;
	align-items: center;
}

.filter_panel_mobile .input_box:hover {
	border: 1px solid var(--COLOR-PRIMARY);
}

.filter_panel_mobile .input_box:focus {
	border: 1px solid var(--COLOR-PRIMARY);
}

.filter_panel_mobile .label {
	color: var(--COLOR-TXT);
	font-weight: 500;
}

body .modal_container {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

body .modal_container {
	backdrop-filter: blur(1px);
}

.filter__modal {
	position: absolute;
}
