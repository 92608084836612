.note {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	gap: 0.5rem;
	width: 70rem;
	border-radius: 8px;
	position: relative;
	padding-bottom: 2px;
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	color: var(--COLOR-TXT);
}

.note:hover {
	box-shadow: var(--CARD-BOX-SHADOW);
}

.note--displayed {
	width: 31rem;
	list-style-type: none;
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.note--displayed:hover {
	box-shadow: var(--CARD-BOX-SHADOW);
}

.note__form {
	width: 100%;
}

.note__heading {
	width: 100%;
	background-color: inherit;
	outline: none;
	border: 0;
	padding: 6px;
	color: var(--COLOR-TXT);
}

.note__textarea {
	background-color: inherit;
	width: 100%;
	outline: none;
	border: 0;
	padding: 6px;
	color: inherit;
}

.note__heading:focus,
.note__textarea:focus,
.select--filter_by:focus {
	background-color: inherit;
	font-weight: 500;
}

.note__footer {
	display: flex;
	align-items: center;
	padding: 5px 1.5rem;
	border-top: 1px solid var(--COLOR-PRIMARY-DARK-THEME);
}

.note--displayed .note__footer {
	flex-flow: column nowrap;
	gap: 8px;
	padding: 8px;
}

.note__created_date {
	font-weight: 400;
}

.note_props {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 3px 1rem;
	cursor: default;
}

.note__priority {
	font-size: 14px;
}

.note__cta {
	display: flex;
	justify-content: space-evenly;
	margin-left: auto;
	gap: 1rem;
}

.note--displayed .note__cta {
	margin-left: 0;
	justify-content: space-between;
	gap: 3.6rem;
}

.btn_note__cta {
	cursor: pointer;
	background-color: inherit;
	border: none;
	font-size: 2.6rem;
	padding: 0;
	height: fit-content;
	display: grid;
	place-content: center;
	color: var(--COLOR-TXT);
	color: inherit;
}

.note__title {
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 95%;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	line-height: 1.5;
	font-size: 2rem;
}

.note__content {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	font-size: 1.7rem;
}

.note__body {
	padding: 1.5rem;
	text-overflow: ellipsis;
}

.note--displayed .note__body {
	width: 100%;
	display: flex;
	gap: 1rem;
	flex-flow: column nowrap;
}

.btn__pin_it {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	font-size: 2.6rem;
	right: 1rem;
	top: 1.5rem;
	background-color: inherit;
	border-radius: 50%;
}

.btn__cross {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	font-size: 2.6rem;
	right: 1rem;
	top: 1.5rem;
	background-color: inherit;
	border-radius: 50%;
	transition: all 0.3s ease-in;
}

.btn__cross:hover {
	box-shadow: 0px 0px 1px 1px var(--COLOR-CARD-BOX-SHADOW2),
		0px 0px 1px 5px var(--COLOR-CARD-BOX-SHADOW3);
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--COLOR-TXT);
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--COLOR-TXT);
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--COLOR-TXT);
}

@media only screen and (max-width: 470px) {
	.note__created_date {
		font-weight: 400;
		display: none;
	}
}
