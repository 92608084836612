.main--home_page {
	grid-area: main;
	/* display: flex; */
	flex-flow: row nowrap;
	min-height: 100%;
}

.note_editor_section {
	display: flex;
	flex-flow: column wrap;
	padding: 3rem;
	padding-bottom: 1rem;
	gap: 2.5rem;
}

.note_container {
	display: flex;
	flex-flow: column nowrap;
	width: 70rem;
}

.note_lisiting_section {
	display: flex;
	flex-flow: column nowrap;
	padding: 3rem;
	padding: 1rem 3rem;
	gap: 2rem;
	height: 100%;
}

.scroll_to_top_icon {
	position: fixed;
	bottom: 7rem;
	right: 1.5rem;
	z-index: var(--z-index-2);
}
