.note__footer {
	position: relative;
}

.note_label__form {
	/* position: absolute;
	top: 6px;
	right: 12.5rem; */

	position: absolute;
	top: 6px;
	right: 1.5rem;
	border: 1px solid var(--COLOR-TXT);
	border-radius: 8px;
	z-index: var(--z-index-4);
	outline: var(--COLOR-TXT);
}

.note .note_label__input {
	background-color: inherit;
	/* outline: none; */
	border: 0;
	width: 10rem;
	padding: 6px;
	color: var(--COLOR-TXT);
}

.note .select {
	width: 17ch;
	margin-left: 2rem;
}
